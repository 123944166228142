@charset "UTF-8";
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  /* Impression Rendement des fonds (Particuliers, entreprises et groupes, Cumis, TD, MD) */
  h1,
  h2,
  h3 {
    font-weight: bold;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    padding: 0 0 2px 0;
    margin: 0 0 5px 0;
  }
  h3 {
    font-size: 13px;
    margin: 0 0 2px 0;
  }
  p {
    font-size: 11px;
    /*	font-family: times new roman, serif; */
    margin: 0 0 18px 0;
  }
  a {
    text-decoration: underline;
  }
  a[href]:after {
    content: none !important;
  }
  .row {
    display: block !important;
  }
  .row > [class*=col-] {
    float: none;
  }
  .page-content {
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    top: 25px;
    width: 750px;
  }
  .page-content .collapse {
    display: block !important;
    visibility: visible !important;
  }
  .selecteur-colonne-cachee {
    display: table-cell;
  }
  .titre {
    padding-bottom: 10px;
  }
  header,
  .navbar-header-mobile div.logo-ia {
    background: #ffffff !important;
  }
  #nav-primaire,
  .navbar-nav,
  .navbar-left,
  .navbar-right,
  .navbar-right li,
  .header-content {
    display: none;
  }
  .search .icone-loupe,
  .language span,
  .connexion .dropdown-toggle {
    display: none;
  }
  .selecteur-colonne {
    display: none;
  }
  .logo-ia {
    display: block !important;
    visibility: visible !important;
  }
  #footer,
  footer {
    display: none;
    height: 0;
  }
  footer a[href] {
    display: none;
  }
  .footer-links,
  .footer-links-right,
  .footer-links-left,
  .footer-social-links,
  .ia-slogan,
  .ia-copy {
    display: none;
  }
  #main {
    margin-top: 50px;
  }
  .mod-comm-conseiller,
  .mod-cyberclient {
    display: none;
  }
  .separateur {
    display: none;
  }
  .call-to-action {
    display: none !important;
  }
  .voir-aussi,
  .call-to-action-content {
    display: none;
  }
  .icone-exclamation-formulaire {
    display: none;
  }
  #RendementLegalContent .acordion-title {
    margin-bottom: 0;
  }
  #RendementLegalContent {
    border: 2px solid #f2f2f2;
  }
  #legalContent {
    border: none !important;
  }
  #RendementLegalContent.accordeon-section-titre[aria-expanded=true],
  #RendementLegalContent.accordeon-section-titre[aria-expanded=false] {
    border: none;
  }
  .accordeon-gris .accordion-heading .accordeon-section-titre[aria-expanded=true],
  .accordeon-bleu .accordion-heading .accordeon-section-titre[aria-expanded=true],
  .accordeon-gris .accordion-heading .accordeon-section-titre[aria-expanded=false],
  .accordeon-bleu .accordion-heading .accordeon-section-titre[aria-expanded=false] {
    border: 1px solid #f2f2f2 !important;
  }
  .popover,
  .popover.top,
  .popover.top > .arrow,
  .popover button,
  .popover .popover-content,
  .popover .popover-content p,
  .popover .arrow,
  .popover .arrow:after,
  .popover button#close,
  .popover button#close .icone-validation-erreur,
  .popover button#close .icone-validation-erreur:before {
    display: none !important;
  }
  /** page-CalculateurVersementHypothecaire  */
  .page-CalculateurVersementHypothecaire .btn-slider {
    display: none;
  }
  .page-CalculateurVersementHypothecaire #MiseDeFondsNecessaire {
    margin-top: -46px;
    font-size: 12px;
  }
  .page-CalculateurVersementHypothecaire #exempleMontantPropriete {
    display: none;
  }
  .page-CalculateurVersementHypothecaire .panneau-info.fond-formulaire {
    width: 30%;
    float: left;
    height: auto !important;
  }
  .page-CalculateurVersementHypothecaire .panneau-info {
    width: 70%;
    float: right;
    height: auto !important;
  }
  .page-CalculateurVersementHypothecaire label.info-label {
    display: none;
  }
  .page-CalculateurVersementHypothecaire #repartition + .margin-top-20,
  .page-CalculateurVersementHypothecaire #form_calculateur_versements > .row.fond-formulaire.bleu-pale,
  .page-CalculateurVersementHypothecaire #btn_calculer {
    display: none !important;
  }
  .page-CalculateurVersementHypothecaire #amortissement, .page-CalculateurVersementHypothecaire #astuce {
    border-top: none;
  }
  .page-CalculateurVersementHypothecaire #repartition {
    padding-bottom: 10px;
    border-bottom: 2px solid #e9e9e9;
  }
  #calculateur-pret-hypotecaire canvas {
    margin: 0 auto;
  }
  .page-CalculateurVersementHypothecaire #calculateur-pret-hypotecaire {
    border: 1px solid #fff;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px !important;
  }
  .page-CalculateurVersementHypothecaire .row {
    page-break-inside: avoid;
  }
  .page-CalculateurVersementHypothecaire .boite-intro, .page-CalculateurVersementHypothecaire .info-calculateur, .page-CalculateurVersementHypothecaire .panneau-info.fond-formulaire {
    padding: 0 !important;
  }
  .page-CalculateurVersementHypothecaire .fond-formulaire {
    padding: 20px 0px 20px 40px;
  }
  .page-CalculateurVersementHypothecaire p {
    margin: 5px 0 !important;
  }
  .page-CalculateurVersementHypothecaire h1 {
    margin-top: 10px;
  }
  .page-CalculateurVersementHypothecaire #mentionLegal, .page-CalculateurVersementHypothecaire #mentionLegal p {
    font-size: 11px;
  }
  .intro-page-texte, .boite-intro-texte, .boite-intro p {
    font-size: 14px;
  }
  #choixJudicieux {
    margin-top: 15px;
  }
  .imprimer-fonds {
    display: none !important;
  }
  canvas {
    display: block !important;
  }
  /*	Calculateur capacite d'emprunt */
  #calculateur-pret-hypotecaire.capacite {
    border: 0;
  }
  #calculateur-pret-hypotecaire.capacite .panneau-info {
    height: auto !important;
  }
  #calculateur-pret-hypotecaire.capacite .fond-formulaire {
    padding: 0;
    border: 0;
  }
  #calculateur-pret-hypotecaire.capacite .col-med-3-4 {
    width: 75%;
    float: left;
  }
  #calculateur-pret-hypotecaire.capacite .col-med-1-4 {
    width: 25%;
    float: left;
  }
  #calculateur-pret-hypotecaire.capacite input[type=number], #calculateur-pret-hypotecaire.capacite input[type=text] {
    height: 20px;
    min-height: 0;
    padding: 0;
    width: 100%;
  }
  #calculateur-pret-hypotecaire.capacite #btn_calculer {
    display: none;
  }
  #calculateur-pret-hypotecaire.capacite #resultats {
    padding: 0 10px !important;
  }
  #calculateur-pret-hypotecaire.capacite .icone-info-centre {
    margin-top: -15px;
  }
  #calculateur-pret-hypotecaire.capacite .row.separateur-haut + .row.margin-top-20 {
    display: none;
  }
  #form_calculateur_capacite .col-3-3.col-med-1-3 {
    width: 33%;
    float: left;
    padding-right: 10px;
  }
  #form_calculateur_capacite .col-3-3.col-med-1-3 h2 {
    min-height: 47px;
  }
  .fond-formulaire.assVie, .fond-formulaire.assVie .row,
  .fond-formulaire.tile-icon.haut-droit.icone-dollar-rond,
  .assVie .spanNextToInput, .inputSlide-ia,
  .page-calc-assVie .inputSlide-ia {
    padding: 0 !important;
  }
  .fond-formulaire.assVie h2 {
    margin: 0 !important;
  }
  .fond-formulaire.assVie .icone-deco-triangle + .row {
    padding: 0;
  }
  .page-calc-assVie .spanNextToInput [class^=icone] {
    display: none;
  }
  #assVie #clickMe, #assVieResult .goBack, .page-calc-assVie .nextToInput {
    display: none !important;
  }
  .assVie .separateurDegrade {
    border: 0;
  }
  .slider.slider-horizontal {
    display: none;
  }
  .page-calc-assVie input[type=text] {
    min-height: 16px;
    margin: 0 0 15px;
  }
  .assVie .separateurDegrade:before {
    content: "";
    display: none !important;
    width: 0px;
    height: 0px;
  }
  #assVie fieldset {
    page-break-inside: avoid;
  }
  .inputSlide-ia.radio label.active {
    margin-top: 10px;
    text-decoration: underline;
  }
  #assVie legend[for=montantDettes],
  #assVie legend[for=Formulaire_nbrEnfant] {
    margin-bottom: -10px;
  }
  .inputSlide-ia.radio label:not(.active), .page-calc-assVie .icone-deco-triangle {
    display: none;
  }
  .fond-formulaire.assVie {
    page-break-after: avoid;
  }
  .page-calc-assVie #portraitFinancier {
    margin-top: 0 !important;
  }
  .page-calc-assVie #main {
    margin-top: -55px;
  }
  .page-calc-assVie .row.cta-rel {
    height: 120px;
  }
  .page-calc-assVie .btn-radio-ia:not(.active) {
    display: none !important;
  }
  .page-calc-assVie #assVieResult #numeroResult {
    margin-top: -47px;
    margin-bottom: -27px;
  }
  .page-calc-assVie #assVie label.beforeIcon {
    padding: 0px;
  }
  .fond-formulaire .separateurDegrade:after {
    content: "";
    display: none;
  }
  /*
      BTN RADIOS
  */
  .btn-radio-ia i {
    display: none;
  }
  .btn-group-ia {
    margin-top: 0px;
  }
  .btn-radio-ia.active .outside {
    text-decoration: underline;
  }
  /*
        page calculateur epargne retraite
    */
  .page-calcualteurEpargneRetraite .boite-intro {
    padding: 0;
  }
  .page-calcualteurEpargneRetraite .boite-intro p {
    display: none;
  }
  .page-calcualteurEpargneRetraite fieldset.fond-formulaire {
    padding: 15px 0 !important;
  }
  .page-calcualteurEpargneRetraite .unRegime .wrapper {
    border: none;
  }
  /*
      Custom select
  */
  .customSelect.liste-deroulante-grise:after {
    display: none;
  }
  .page-epargne-reee .call-to-action.mod-boutons-actions,
  .page-epargne-reee .reee.lienCommentCaFonctionne,
  .page-epargne-reee .deja-client,
  .page-epargne-reee .voir-aussi,
  .page-epargne-reee .slider-box {
    display: none !important;
    height: 0;
  }
  .page-epargne-reee .layout-bas .col-med-2-3, .page-epargne-reee .layout-bas .col-med-1-3 {
    width: 100% !important;
  }
  .page-epargne-reee .layout-bas #Pourquoi {
    height: 420px !important;
  }
  .page-epargne-reee.printResult .calculateur.resultats > div > div {
    display: block;
    float: left;
  }
  .page-epargne-reee.printResult .call-to-action.mod-boutons-actions,
  .page-epargne-reee.printResult .reee.lienCommentCaFonctionne,
  .page-epargne-reee.printResult .section.equal-height.col-1-1.correction-margin.sectionImage,
  .page-epargne-reee.printResult .row.sections.groupe-bloc-contenu-fond-gris.reee.margin-decalage,
  .page-epargne-reee.printResult .row.layout-centre-bas,
  .page-epargne-reee.printResult .slider-box,
  .page-epargne-reee.printResult .layout-bas,
  .page-epargne-reee.printResult .layout-bas + .row,
  .page-epargne-reee.printResult .tester h4 {
    display: none !important;
    height: 0;
  }
  .page-epargne-reee.printResult .calculateur h1 {
    margin-top: -45px;
    margin-bottom: -93px;
  }
  .page-epargne-reee.printResult .msgMaxCotisationSubv.msg-bulle {
    margin: 0 !important;
  }
  .page-qualificationREEE.achat.printResult .page-home, .page-qualificationREEE.achat.printResult .page-refus, .page-qualificationREEE.achat.printResult .page-info, .page-qualificationREEE.achat.printResult .page-infoBC, .page-qualificationREEE.achat.printResult .page-beneficiaire, .page-qualificationREEE.achat.printResult .page-summary {
    display: none !important;
  }
  .page-form-invalidite #main.content group {
    margin-top: 0;
  }
  #VM-invalidite .boite-intro {
    padding-top: 0;
  }
  #VM-invalidite.printResult #form-disability-claims-step1, #VM-invalidite.printResult #form-disability-claims-step2, #VM-invalidite.printResult #form-disability-claims-summary {
    display: none !important;
  }
  #VM-invalidite .light-grey-tiles {
    page-break-inside: avoid;
  }
  #VM-invalidite #confirm {
    margin-bottom: 0;
  }
  #VM-invalidite #confirm .icone-courriel-confirmation {
    display: block;
    float: left;
  }
  #VM-invalidite #confirm .icone-courriel-confirmation:before {
    font-size: 50px;
  }
  #VM-invalidite #confirm .header {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    border-top: none;
    border-bottom: none;
  }
  #VM-aiguillage-contact, #stickyREEE {
    display: none !important;
  }
  .print_pba_always {
    page-break-after: always;
  }
  .print_pba_avoid {
    page-break-after: avoid;
  }
  .hidePrint, .print_hide {
    display: none !important;
  }
  sup {
    line-height: 0 !important;
    vertical-align: baseline !important;
  }
  .showPrint, .print_show {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
  .print_w_50 {
    width: 50% !important;
  }
  .print_w_40 {
    width: 40% !important;
  }
  .print_w_33 {
    width: 33.333% !important;
  }
  .print_w_66 {
    width: 66.666% !important;
  }
  .print_w_100 {
    width: 100% !important;
  }
  .print_w_auto {
    width: auto !important;
  }
  .print_pt_20 {
    padding-top: 20px;
  }
  .print_p_0 {
    padding: 1px !important;
  }
  .print_m_0 {
    margin: 1px !important;
  }
  .print_mp_0 {
    margin: 1px !important;
    padding: 1px !important;
  }
  .print_f_l {
    display: block;
    float: left !important;
    padding-right: 10px;
  }
  .print_f_r {
    display: block;
    float: right !important;
  }
  .print_h_150 {
    height: 150px;
  }
  /*Calulateur ExtensiA*/
  .page-calculateur-extensia {
    background: red;
  }
  .page-calculateur-extensia .intro-extensia h1 {
    margin-bottom: -20px !important;
    margin-left: 18px;
  }
  .page-calculateur-extensia .page-content {
    padding-bottom: 0;
  }
  .page-calculateur-extensia .btn-link,
  .page-calculateur-extensia .btn-trouver,
  .page-calculateur-extensia .btn-link.annuler {
    display: none;
  }
  .page-calculateur-extensia #sommaire-souscripteur h2 {
    margin-top: 0;
  }
  .page-calculateur-extensia #sommaire-souscripteur .section-sommaire:nth-child(3) .bloc-contenu-fond-gris-avec-coin {
    page-break-before: always;
  }
  .page-calculateur-extensia #sommaire-souscripteur .section-sommaire:nth-child(3) .bloc-contenu-fond-gris-avec-coin h2 {
    margin-top: -20px;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin .field-input {
    margin-top: -15px;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin .field-input label {
    page-break-inside: avoid;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin .field-input label p {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin .field-input label p:first-of-type {
    margin-top: 0;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin .field-input label p:last-of-type {
    margin-bottom: 0;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin .table.colore,
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin .a-propos-details,
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin .a-propos-assures {
    margin: 20px 0 0 0;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin .a-propos-details > li:last-of-type,
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin .a-propos-assures > li:last-of-type {
    margin-bottom: 0;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin table.colore {
    border: solid 1px #ccc;
    page-break-inside: avoid;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin table.colore tr,
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin table.colore th,
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin table.colore td {
    border: solid 1px #ccc;
  }
  .page-calculateur-extensia #sommaire-souscripteur .bloc-contenu-fond-gris-avec-coin table.colore thead th.empty {
    background-color: #f9f9f9 !important;
  }
  .page-calculateur-extensia #sommaire-souscripteur .separateur-haut-small {
    display: none;
  }
  .page-calculateur-extensia #sommaire-souscripteur .icone-page-coin {
    display: none;
  }
  /*Empêcher d'avoir l'alert de bas d'écran lors de l'impression*/
  #alerteBottom {
    display: none !important;
  }
  /* Hide Mouseflow at print */
  #mouseflow {
    display: none !important;
  }
  .page-business-funds, .page-taux-des-fonds, .page-taux-collectif, .page-rendement-fonds {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  .page-business-funds #main, .page-taux-des-fonds #main, .page-taux-collectif #main, .page-rendement-fonds #main {
    margin-top: 0px;
  }
  .page-business-funds .page-content, .page-taux-des-fonds .page-content, .page-taux-collectif .page-content, .page-rendement-fonds .page-content {
    width: inherit;
    padding: 0px;
    position: static;
    top: 0px;
  }
  .page-business-funds #bandeau-alerte, .page-taux-des-fonds #bandeau-alerte, .page-taux-collectif #bandeau-alerte, .page-rendement-fonds #bandeau-alerte {
    display: none;
  }
  .page-business-funds .banniereCookies, .page-taux-des-fonds .banniereCookies, .page-taux-collectif .banniereCookies, .page-rendement-fonds .banniereCookies {
    display: none !important;
  }
  .page-business-funds .nav-secondaire, .page-taux-des-fonds .nav-secondaire, .page-taux-collectif .nav-secondaire, .page-rendement-fonds .nav-secondaire {
    display: none;
  }
  .page-business-funds h1, .page-taux-des-fonds h1, .page-taux-collectif h1, .page-rendement-fonds h1 {
    color: #003da5 !important;
    text-transform: uppercase;
    font-weight: 900;
    margin: 0px;
  }
  .page-business-funds .bloc-bta-wrapper, .page-taux-des-fonds .bloc-bta-wrapper, .page-taux-collectif .bloc-bta-wrapper, .page-rendement-fonds .bloc-bta-wrapper {
    display: none;
  }
  .page-business-funds .recherche-filtres, .page-taux-des-fonds .recherche-filtres, .page-taux-collectif .recherche-filtres, .page-rendement-fonds .recherche-filtres {
    padding: 0px !important;
  }
  .page-business-funds .recherche-filtres h2, .page-taux-des-fonds .recherche-filtres h2, .page-taux-collectif .recherche-filtres h2, .page-rendement-fonds .recherche-filtres h2 {
    display: none;
  }
  .page-business-funds .recherche-filtres .showHideFilters, .page-taux-des-fonds .recherche-filtres .showHideFilters, .page-taux-collectif .recherche-filtres .showHideFilters, .page-rendement-fonds .recherche-filtres .showHideFilters {
    display: none;
  }
  .page-business-funds .recherche-filtres input, .page-taux-des-fonds .recherche-filtres input, .page-taux-collectif .recherche-filtres input, .page-rendement-fonds .recherche-filtres input {
    padding: 0px;
    min-height: 0px;
  }
  .page-business-funds .accordeon-item.rendements-fonds, .page-taux-des-fonds .accordeon-item.rendements-fonds, .page-taux-collectif .accordeon-item.rendements-fonds, .page-rendement-fonds .accordeon-item.rendements-fonds {
    border: none !important;
    border-radius: 0 !important;
  }
  .page-business-funds .boite-liens-connexes, .page-taux-des-fonds .boite-liens-connexes, .page-taux-collectif .boite-liens-connexes, .page-rendement-fonds .boite-liens-connexes {
    display: none !important;
  }
  .page-business-funds .accordion-title a, .page-taux-des-fonds .accordion-title a, .page-taux-collectif .accordion-title a, .page-rendement-fonds .accordion-title a {
    background-color: #2062d4 !important;
    padding: 7px 5px 7px 5px !important;
  }
  .page-business-funds .accordeon-bleu .accordion-heading .accordeon-section-titre[aria-expanded=false], .page-taux-des-fonds .accordeon-bleu .accordion-heading .accordeon-section-titre[aria-expanded=false], .page-taux-collectif .accordeon-bleu .accordion-heading .accordeon-section-titre[aria-expanded=false], .page-rendement-fonds .accordeon-bleu .accordion-heading .accordeon-section-titre[aria-expanded=false] {
    border: none !important;
  }
  .page-business-funds .accordeon-collapse, .page-taux-des-fonds .accordeon-collapse, .page-taux-collectif .accordeon-collapse, .page-rendement-fonds .accordeon-collapse {
    margin: 0px !important;
  }
  .page-business-funds .accordeon-item, .page-taux-des-fonds .accordeon-item, .page-taux-collectif .accordeon-item, .page-rendement-fonds .accordeon-item {
    margin: 0px !important;
  }
  .page-business-funds .accordeon-item .trimestre-titre, .page-taux-des-fonds .accordeon-item .trimestre-titre, .page-taux-collectif .accordeon-item .trimestre-titre, .page-rendement-fonds .accordeon-item .trimestre-titre {
    color: #ffffff !important;
    font-weight: 700 !important;
    font-size: 14px !important;
  }
  .page-business-funds .accordeon-group table thead, .page-taux-des-fonds .accordeon-group table thead, .page-taux-collectif .accordeon-group table thead, .page-rendement-fonds .accordeon-group table thead {
    border-bottom: none !important;
  }
  .page-business-funds .accordeon-body, .page-taux-des-fonds .accordeon-body, .page-taux-collectif .accordeon-body, .page-rendement-fonds .accordeon-body {
    padding: 0px !important;
    margin: 0px !important;
  }
  .page-business-funds table, .page-taux-des-fonds table, .page-taux-collectif table, .page-rendement-fonds table {
    overflow: hidden !important;
    border-collapse: separate !important;
  }
  .page-business-funds tbody, .page-taux-des-fonds tbody, .page-taux-collectif tbody, .page-rendement-fonds tbody {
    font-size: 0.75em;
  }
  .page-business-funds thead, .page-taux-des-fonds thead, .page-taux-collectif thead, .page-rendement-fonds thead {
    display: table-row-group;
  }
  .page-business-funds thead, .page-business-funds thead tr, .page-business-funds thead tr th, .page-taux-des-fonds thead, .page-taux-des-fonds thead tr, .page-taux-des-fonds thead tr th, .page-taux-collectif thead, .page-taux-collectif thead tr, .page-taux-collectif thead tr th, .page-rendement-fonds thead, .page-rendement-fonds thead tr, .page-rendement-fonds thead tr th {
    background-color: #f2f2f2 !important;
  }
  .page-business-funds thead tr.filter-header, .page-taux-des-fonds thead tr.filter-header, .page-taux-collectif thead tr.filter-header, .page-rendement-fonds thead tr.filter-header {
    font-size: 0.75em;
  }
  .page-business-funds tr td, .page-business-funds tr th, .page-taux-des-fonds tr td, .page-taux-des-fonds tr th, .page-taux-collectif tr td, .page-taux-collectif tr th, .page-rendement-fonds tr td, .page-rendement-fonds tr th {
    border-top: 1px solid black;
  }
  .page-business-funds tr:last-child td, .page-taux-des-fonds tr:last-child td, .page-taux-collectif tr:last-child td, .page-rendement-fonds tr:last-child td {
    border-bottom: 1px solid black;
  }
  .page-business-funds th, .page-taux-des-fonds th, .page-taux-collectif th, .page-rendement-fonds th {
    border-bottom: none !important;
    width: inherit !important;
    min-width: inherit !important;
    padding: 0px 5px 0px 5px !important;
    vertical-align: middle !important;
  }
  .page-business-funds th, .page-business-funds td, .page-taux-des-fonds th, .page-taux-des-fonds td, .page-taux-collectif th, .page-taux-collectif td, .page-rendement-fonds th, .page-rendement-fonds td {
    border-left: 1px solid black !important;
  }
  .page-business-funds th:last-child, .page-business-funds td:last-child, .page-taux-des-fonds th:last-child, .page-taux-des-fonds td:last-child, .page-taux-collectif th:last-child, .page-taux-collectif td:last-child, .page-rendement-fonds th:last-child, .page-rendement-fonds td:last-child {
    border-right: 1px solid black !important;
  }
  .page-business-funds td, .page-taux-des-fonds td, .page-taux-collectif td, .page-rendement-fonds td {
    vertical-align: middle !important;
    padding: 0px 5px 0px 5px !important;
  }
  .page-business-funds td a, .page-taux-des-fonds td a, .page-taux-collectif td a, .page-rendement-fonds td a {
    text-decoration: none !important;
  }
  .page-business-funds td p, .page-taux-des-fonds td p, .page-taux-collectif td p, .page-rendement-fonds td p {
    margin: 0px;
  }
  .page-business-funds .boite-liens-connexes, .page-taux-des-fonds .boite-liens-connexes, .page-taux-collectif .boite-liens-connexes, .page-rendement-fonds .boite-liens-connexes {
    display: none;
  }
  .page-taux-des-fonds table tr, .page-taux-des-fonds table th, .page-taux-des-fonds table td {
    height: 0px !important;
  }
  .page-taux-des-fonds table tr th {
    line-height: 1.4;
  }
  .page-taux-des-fonds label, .page-taux-des-fonds .print-notes p, .page-taux-des-fonds .taux-interet p, .page-taux-des-fonds .resultats-titres li, .page-taux-des-fonds .resultats-titres .print-only p {
    margin: 0px !important;
  }
  .page-taux-des-fonds .resultats-titres > p {
    margin: 0px 0px 5px 0px !important;
  }
  .page-taux-des-fonds .resultats-titres, .page-taux-des-fonds #filtersCollapse {
    font-size: 11px;
  }
  .page-taux-des-fonds .resultats-titres > h2, .page-taux-des-fonds .resultats-titres > a, .page-taux-des-fonds .tab-header br, .page-taux-des-fonds .placement > div > br {
    display: none;
  }
  .page-taux-des-fonds table, .page-taux-des-fonds #taux-item-3 {
    margin: 0px 0px 10px 0px !important;
  }
  .page-taux-des-fonds .recherche-resultats {
    margin: 5px 0px 0px 0px !important;
  }
  .page-taux-des-fonds table td:nth-child(2), .page-taux-des-fonds td:nth-child(3), .page-taux-des-fonds td:nth-child(4), .page-taux-des-fonds td:nth-child(5), .page-taux-des-fonds td:nth-child(6) {
    width: 150px;
  }
  .page-taux-collectif .recherche-resultats {
    margin: 20px 0px 0px 0px !important;
  }
  .page-taux-collectif .resultats-titres {
    display: none;
  }
  .page-taux-collectif table {
    page-break-after: always;
    margin: 0px !important;
  }
  .page-business-funds .recherche-resultats {
    margin: 20px 0px 0px 0px !important;
  }
  .page-business-funds .resultats-titres {
    display: none;
  }
  .page-business-funds table {
    page-break-after: always;
    margin: 0px !important;
  }
  .page-business-funds table td:nth-child(3), .page-business-funds td:nth-child(4), .page-business-funds td:nth-child(5) {
    width: 10%;
  }
  .page-business-funds table td:nth-child(6), .page-business-funds td:nth-child(7), .page-business-funds td:nth-child(8), .page-business-funds td:nth-child(9), .page-business-funds td:nth-child(10), .page-business-funds td:nth-child(11), .page-business-funds td:nth-child(12) {
    width: 7%;
  }
  .page-rendement-fonds h1 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .page-rendement-fonds .zone-tabs, .page-rendement-fonds fieldset#RendementsDisponibiliteProduit, .page-rendement-fonds fieldset#Produit {
    display: none;
  }
  .page-rendement-fonds fieldset#RendementFieldsetDates div label {
    display: none;
  }
  .page-rendement-fonds .resultats-titres .titre {
    border-bottom: none;
  }
  .page-rendement-fonds .table-rendements-fonds {
    margin-top: 0px !important;
  }
  .page-rendement-fonds .accordeon-item {
    margin-top: 10px !important;
  }
}